import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'

import { PrimaryLayout } from '@id-purchase-funnel-monorepo/shared'

import Navbar from './Navbar'
import Footer from './Footer'
import BrandStyle from './BrandStyle'

import { colors } from '../config/theme'

interface LayoutInterface {
  location: any
  isSorry: boolean
  bg?: string
  textColor?: string
}

const Layout: React.FC<LayoutInterface> = (props) => {
  const { location, bg, textColor, children, isSorry } = props

  const pathname = location.pathname

  const noNav = ['/']
  const hideNav = noNav.some((route) => route === pathname)

  const noFooter = ['/']
  const hideFooter = noFooter.some((route) => route === pathname)

  return (
    <PrimaryLayout bg={colors.navy} spinnerColor={colors.orange}>
      <BrandStyle />
      <ThemeProvider theme={{ colors }}>
        {!hideNav && <Navbar bg={bg} isSorry={isSorry} />}
        {children}
        {!hideFooter && (
          <Footer bg={bg} textColor={textColor} currentPath={pathname} />
        )}
      </ThemeProvider>
    </PrimaryLayout>
  )
}

Layout.propTypes = {
  location: PropTypes.object.isRequired,
}

export default Layout
