import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import { SharedFooter, media } from '@id-purchase-funnel-monorepo/shared'
import { SmallP, MonoP } from './shared/text'

const Container = styled.footer`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.bg};
  color: ${(props) => props.textColor};
`

const ContentWrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  position: relative;
  ${media.mobile`
    max-width: 95%;
    height: 120px;
  `}
  ${media.tablet`
    max-width: 700px;
    height: 75px;
  `}
  ${media.desktop`
    max-width: 1265px;
    padding: 0 50px;
  `}
`

const PrevLink = styled(MonoP)`
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  transform: translate(-50%, 0);
  font-size: 16px;

  &:hover {
    font-weight: bolder;
  }

  ${media.mobile`
    left: 50%;
    top: 0;
  `}
  ${media.tablet`
    left: 50px;
    top: 10px;
  `}
  ${media.desktop`
    left: 100px;
    top: 10px;
  `}
`

const LegalTermsContainer = styled.div`
  width: 90%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;

  ${media.mobile`
    bottom: 40px;
    max-width: 70%;
  `}
  ${media.tablet`
    bottom: 17px;
    max-width: 100%;
  `}

  & p {
    font-family: Larsseit-Bold, sans-serif;
    & a {
      color: ${(props) => props.theme.colors.orange};
      text-decoration: none;
      &: hover {
        text-decoration: underline;
      }
    }
  }
`

interface FooterInterface {
  currentPath: string
  bg: string
  textColor: string
}

export default ({ currentPath, bg, textColor }: FooterInterface) => {
  const noPrev = ['/offer/user-information', '/offer/confirm-order']
  const hidePrevious = noPrev.some((pathname) => pathname === currentPath)

  const LegalTerms = () => (
    <SmallP>
      Click here to view our{' '}
      <a
        href="https://www.petfinn.com/privacy"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>{' '}
      and{' '}
      <a
        href="https://www.petfinn.com/terms"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms and Conditions
      </a>
      .
    </SmallP>
  )

  return (
    <SharedFooter>
      <Container bg={bg} textColor={textColor}>
        <ContentWrapper>
          {!hidePrevious && (
            <PrevLink onClick={() => navigate(-1)}>← Back</PrevLink>
          )}
          <LegalTermsContainer>
            <LegalTerms />
          </LegalTermsContainer>
        </ContentWrapper>
      </Container>
    </SharedFooter>
  )
}
