import React from 'react'
import styled from 'styled-components'


const Navbar = styled.header`
  width: 100%;
`

const Content = styled.div`
  margin: 0 auto;
`

export default ({ children }) => (
  <Navbar>
    <Content>{children}</Content>
  </Navbar>
)
