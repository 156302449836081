import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

interface SEOInterface {
  data?: {
    site?: {
      siteMetadata: {
        title: string
        description: string
        titleTemplate: string
        ogImage: string
        favicon: string
      }
    }
  }
  title?: string
  description?: string
  image?: string
}

const SEO: React.FC<SEOInterface> = ({ title, description, image, data }) => {
  const metaDescription = description || data.site.siteMetadata.description
  const metaImage = image || data.site.siteMetadata.ogImage

  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title={title || data.site.siteMetadata.title}
      link={[
        {
          rel: 'icon',
          type: 'image/png',
          href: data.site.siteMetadata.favicon,
        },
      ]}
      meta={[
        { name: `description`, content: metaDescription },
        { property: `og:title`, content: title },
        { property: `og:description`, content: metaDescription },
        { property: `og:type`, content: `website` },
        { property: `og:image`, content: metaImage },
      ]}
    />
  )
}

SEO.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
}

SEO.defaultProps = {
  title: '',
  description: '',
  image: '',
}

export default SEO
