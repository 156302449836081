import React from 'react'
import styled from 'styled-components'

import { media } from '@id-purchase-funnel-monorepo/shared'

const ArrowSVG = styled.svg`
  margin-left: 9px;
  fill: inherit;
  ${media.mobile`
    margin-bottom: -3px;
    height: 26px;
    width: 26px;
  `}
  ${media.tablet`
    height: 18px;
    width: 18px;
  `}
  ${media.desktop`
    height: 24px;
    width: 24px;
  `}
`

const BasicButton = styled('button')<{
  bg?: string
  color?: string
  noScale?: boolean
}>`
  font-size: 26px;

  background-color: ${(props) =>
    props.bg ? props.bg : props.theme.colors.orange};
  border: 1px solid
    ${(props) => (props.bg ? props.bg : props.theme.colors.orange)};
  color: ${(props) => (props.color ? props.color : props.theme.colors.navy)};
  fill: ${(props) => (props.color ? props.color : props.theme.colors.navy)};
  border-radius: 60px;
  outline: none;
  margin: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 200ms all ease-in-out;
  height: fit-content;
  width: fit-content;
  padding: 14px 26px 12px 26px;

  &:hover {
    ${media.desktop`
      transform: ${(props: { noScale?: boolean }) =>
        props.noScale ? 'none' : 'scale(1.07)'};
    `}
  }
`

const ArrowSVGPath = styled.path`
  fill: inherit;
`

interface ButtonProps {
  copy: string
  type?: 'button' | 'submit' | 'reset' | undefined
  onClick?: () => void
  bg?: string
  color?: string
  arrow?: boolean
  dark?: boolean
  navDrawer?: boolean
  noScale?: boolean
  className?: string
}

const ArrowIcon = () => (
  <ArrowSVG viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ArrowSVGPath d="M6.8 0L13.5 6.1L6.8 12.2L5.8 11L10.2 7H0V5.2H10.2L5.7 1.2L6.8 0Z" />
  </ArrowSVG>
)

export const Button: React.FC<ButtonProps> = ({
  copy,
  onClick,
  arrow,
  bg,
  color,
  type,
  noScale = false,
  className,
}) => {
  return (
    <BasicButton
      type={type || 'button'}
      onClick={onClick}
      bg={bg}
      color={color}
      noScale={noScale}
      className={className}
    >
      {copy}
      {arrow && <ArrowIcon />}
    </BasicButton>
  )
}
