import React from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'

const StylingWrapper = styled.div`
  display: grid;
  place-items: center;
  grid-row-gap: 30px;
  grid-template-columns: 1fr;
`

const RadioRow = styled.div`
  width: fit-content;
  display: flex;
  margin-bottom: 23px;

  &:hover {
    color: ${(props) => props.theme.colors.orange};
  }

  & input {
    width: 17px;
    height: 17px;
    margin: 3px 9px 0 0;
    cursor: pointer;
    border-color: ${(props) => props.theme.colors.lightGrey};
  }

  & label {
    font-size: 24px;
    cursor: pointer;
  }
`

interface SelectFormInterface {
  screen: any
  Button: React.ReactNode
  submit: (data: any) => void
  answers: { [key: string]: string }
  className?: string
}

const SharedSelectForm: React.FC<SelectFormInterface> = (props) => {
  const { submit, screen, className, Button, answers } = props

  const screenDefaultValue = screen.defaultValue
  const existingAnswer = answers[screen.id]

  const defaultAnswer = existingAnswer || screenDefaultValue || null

  const formOptions = defaultAnswer
    ? { defaultValues: { [screen.id]: defaultAnswer } }
    : {}

  const { handleSubmit, register } = useForm(formOptions)

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className={className}
      style={{ width: 'fit-content' }}
    >
      <StylingWrapper>
        <div>
          {screen.options.map((option) => {
            return (
              <RadioRow key={option.value}>
                <input
                  type="radio"
                  id={option.value}
                  name={screen.id}
                  value={option.value}
                  ref={register({ required: 'This field is required' })}
                />
                <label htmlFor={option.value}>{option.label}</label>
              </RadioRow>
            )
          })}
        </div>
        <Button copy="Continue" arrow type="submit" />
      </StylingWrapper>
    </form>
  )
}

export default SharedSelectForm
