import { createGlobalStyle } from 'styled-components'

import Athletics from '../assets/fonts/Athletics-Medium.otf'
import Larsseit from '../assets/fonts/Larsseit.otf'
import LarsseitBold from '../assets/fonts/Larsseit-Bold.otf'
import LarsseitMedium from '../assets/fonts/Larsseit-Medium.otf'
import LarsseitRegular from '../assets/fonts/Larsseit-Regular.otf'
import LarsseitLight from '../assets/fonts/Larsseit-Light.otf'
import Monosten from '../assets/fonts/Monosten-A.otf'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Athletics';
    src: url(${Athletics});
    font-style: normal;
    font-display: block;
    font-weight: 400;
  }

  @font-face {
    font-family: 'Larsseit';
    src: url(${Larsseit});
    font-style: normal;
    font-display: block;
    font-weight: 400;
  }
  @font-face {
    font-family: 'Larsseit-Bold';
    src: url(${LarsseitBold});
    font-style: normal;
    font-display: block;
    font-weight: 400;
  }
  @font-face {
    font-family: 'Larsseit-Medium';
    src: url(${LarsseitMedium});
    font-style: normal;
    font-display: block;
    font-weight: 400;
  }
  @font-face {
    font-family: 'Larsseit-Regular';
    src: url(${LarsseitRegular});
    font-style: normal;
    font-display: block;
    font-weight: 400;
  }
  @font-face {
    font-family: 'Larsseit-Light';
    src: url(${LarsseitLight});
    font-style: normal;
    font-display: block;
    font-weight: 400;
  }
  @font-face {
    font-family: 'Monosten';
    src: url(${Monosten});
    font-style: normal;
    font-display: block;
    font-weight: 400;
  }

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  body {
    font-family: Larsseit, Helvetica, sans-serif;
    color: #161345;
  }

  form {
    margin: 0 auto 0 auto;
  }

  input, select {
    height: 60px;
    width: 100%;
    border-radius: 60px;
    padding: 0 20px;
    border: 1px solid transparent;
    color: #161345;
    background-color: #ebebeb;
    font-size: 20px;
  }

  input {
    padding-top: 3px;
  }

  ::invalid {
    border: 1px solid red;
  }

  ::placeholder {
    color: #999999;
  }

  textarea {
    width: 100%;
    height: fit-content;
    min-height: 100px;
    border-radius: 12px;
    padding: 12px 20px;
    border: 1px solid transparent;
    color: #161345;
    font-size: 14px;
    margin: 40px 0 0 0;
  }

  .error-input {
    border: 2px solid red;
  }

  p.error-text {
    font-family: Larsseit-Bold, Helvetica, sans-serif;
    color: red !important;
    font-size: 10px;
    line-height: 18px;
    text-align: left;
    padding-left: 25px;
    width: 100%;
    margin: 0;
  }

  h1 {
    margin: 0 0 29px 0;
  }

  h1, h2, h3, h4, h5, h6, p {
    font-weight: 400;
  }

  #select-input-wrapper {
    cursor: pointer;
  }

  #select-input-wrapper::after {
    content: "";
    width: 18px;
    height: 18px;
    background-color: #000;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    margin: -2px 0 0 -10px;
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
      position: relative;
      padding-left: 30px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #C4C4C4;
      border-radius: 100%;
      background: #fff;
  }

  [type="radio"]:checked + label:before {
    background: #e5e5e5;
    border: 1px solid #e5e5e5;
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: #161345;
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
  }

`

export default GlobalStyle
