import styled from 'styled-components'
import { media } from '@id-purchase-funnel-monorepo/shared'

// H1. 128/128pt
export const H1 = styled.h1`
  font-family: Athletics, sans-serif;
  color: inherit;
  line-height: 1;
  ${media.mobile`
    font-size: 64px;
  `}
  ${media.tablet`
    font-size: 88px;
  `}
  ${media.desktop`
    font-size: 133px;
  `}
`

// H3/Home headline. 64/64pt
export const Headline = styled(H1)`
  color: inherit;
  ${media.mobile`
    font-size: 36px;
  `}
  ${media.tablet`
    font-size: 40px;
  `}
  ${media.desktop`
    font-size: 64px;
  `}
`

// H4/Footer Nav. 52/64pt
export const H4 = styled.h4`
  font-family: Athletics, sans-serif;
  color: inherit;
  font-weight: 400;
  margin-bottom: 30px;

  ${media.mobile`
    font-size: 30px;
    line-height: 36px;
  `}
  ${media.tablet`
    font-size: 40px;
    line-height: 50px;
  `}
  ${media.desktop`
    font-size: 52px;
    line-height: 60px;
  `}
`

// H5/Consultation Main Copy
export const H5 = styled.h5`
  font-family: Athletics, sans-serif;
  color: inherit;
  ${media.mobile`
    font-size: 30px;
    line-height: 36px;
  `}
  ${media.desktop`
    font-size: 46px;
    line-height: 54px;
  `}
`

// Quote/Dek Style/Product Names. 36/46pt
export const H6 = styled.p`
  font-family: Athletics, sans-serif;
  color: inherit;
  ${media.mobile`
    font-size: 28px;
    line-height: 36px;
  `}
  ${media.tablet`
    font-size: 30px;
    line-height: 36px;
  `}
  ${media.desktop`
    font-size: 36px;
    line-height: 46px;
  `}
`

//  Big Article Titles
export const Title = styled.h1`
  font-family: Larsseit, Helvetica, sans-serif;
  color: inherit;
  font-size: 36px;
  line-height: 46px;
`

//  Large Body. 24/36pt
export const LargeP = styled.p`
  font-family: Larsseit, Helvetica, sans-serif;
  color: inherit;
  ${media.mobile`
    font-size: 18px;
    line-height: 24px;
  `}
  ${media.desktop`
    font-size: 24px;
    line-height: 36px;
  `}

  & b {
    font-family: Larsseit-Bold, Helvetica, sans-serif;
  }
`

//  Medium Body. 18/28pt
export const P = styled(LargeP)`
  color: inherit;
  ${media.mobile`
    font-size: 16px;
    line-height: 24px;
  `}
  ${media.tablet`
    font-size: 16px;
    line-height: 24px;
  `}
  ${media.desktop`
    font-size: 18px;
    line-height: 30px;
  `}
`

//  Small text. 14/23pt
export const SmallP = styled(LargeP)`
  color: inherit;
  ${media.mobile`
    font-size: 12px;
    line-height: 18px;
  `}
  ${media.tablet`
    font-size: 12px;
    line-height: 18px;
  `}
  ${media.desktop`
    font-size: 14px;
    line-height: 23px;
  `}
`

export const Error = styled(SmallP)`
  color: red;
  font-size: 10px;
  height: 22px;
`

// Small Technical Detail. 13/13pt
export const MonoP = styled.p`
  font-family: Monosten, Courier, monospace;
  text-transform: uppercase;
  color: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.5px;
`
