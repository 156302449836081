import React from 'react'
import styled from 'styled-components'

import { media } from '../utils/style-helper'

const Footer = styled.footer`
  width: 100%;
`

const Content = styled.div`
  margin: 0 auto;
`

export default ({ children }) => (
  <Footer>
    <Content>{children}</Content>
  </Footer>
)
