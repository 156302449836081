import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import GlobalStyle from './GlobalStyle'
import ErrorBoundary from './ErrorBoundary'
import { FullPageSpinner } from '../components/Spinner'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  min-height: 100vh;
`

const Body = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  min-height: 100vh;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
`

interface PrimaryLayoutInterface {
  bg: any
  spinnerColor?: any
}

// Children will include a Navbar, Main, and Footer
const PrimaryLayout: React.FC<PrimaryLayoutInterface> = ({
  children,
  spinnerColor,
  bg,
}) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    const handleMount = () => setHasMounted(true)
    setTimeout(handleMount, 500)
    return () => clearTimeout(handleMount)
  }, [])

  return !hasMounted ? (
    <FullPageSpinner color={spinnerColor} bg={bg} />
  ) : (
    <Wrapper>
      <GlobalStyle />
      <ErrorBoundary errorText="Sorry, something went wrong.">
        <Body bg={bg}>{children}</Body>
      </ErrorBoundary>
    </Wrapper>
  )
}

export default PrimaryLayout
