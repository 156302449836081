import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { SharedSEO as SEO } from '@id-purchase-funnel-monorepo/shared'

import Homepage from '../components/Homepage'

interface IndexPageInterface {
  location: any
  data: {
    site: {
      siteMetadata: {
        description: string
        favicon: string
        ogImage: string
        title: string
        titleTemplate: string
      }
    }
  }
}

const IndexPage = ({ data, location }: IndexPageInterface) => (
  <>
    <SEO data={data} />
    <Homepage location={location} />
  </>
)

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query HomepageQuery {
    site {
      siteMetadata {
        description
        favicon
        ogImage
        title
        titleTemplate
      }
    }
  }
`

export default IndexPage
