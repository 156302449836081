import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
`

interface SharedQuestionInterface {
  QuestionComponent: React.ReactNode
  loading: boolean
  currentScreen: { [key: string]: any }
  submit: (args: { [key: string]: string }) => void
  next: () => void
  answers?: { [key: string]: string }
  entry?: { [key: string]: string }
  order?: { [key: string]: string }
}

const SharedQuestion: React.FC<SharedQuestionInterface> = (props) => {
  const {
    QuestionComponent,
    currentScreen,
    answers,
    submit,
    next,
    loading,
    entry,
    order,
  } = props

  return (
    <Wrapper>
      <QuestionComponent
        screen={currentScreen}
        submit={submit}
        answers={answers}
        next={next}
        loading={loading}
        entry={entry}
        order={order}
      />
    </Wrapper>
  )
}

export default SharedQuestion
