import React from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

import { ErrorWrapper } from '../text'

import { media } from '../../utils/style-helper'

const Grid = styled.div`
  display: grid;
  place-items: center;

  margin: 0 auto;

  width: 100%;
  height: fit-content;

  ${media.tablet`
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 4px 20px;
    max-width: ${(props) =>
      props.images ? 'none' : props.isUserInfo ? '309px' : '500px'};
  `}
`

const InputWrapper = styled('div')<{ spanCols: number }>`
  height: fit-content;
  width: 100%;
  margin: 0 auto;

  max-width: ${(props) => (props.noMaxWidth ? 'none' : '309px')};

  & input {
    width: 100%;
  }

  & select {
    background-color: ${(props) => props.theme.colors.lightGrey};
    color: ${(props) => props.theme.colors.navy};

    & option {
      font-size: 12px;
      text-align: center;
    }
  }
`

const DoubleImageGrid = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  margin: 40px auto;
  & img {
    object-fit: contain;
  }
  ${media.mobile`
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    place-items: center;
    grid-row-gap: 7px;
    & img {
      height: auto;
      width: 100%;
      object-fit: contain;
    }
  `}
  ${media.tablet`
    width: fit-content;
    grid-template-columns: 2.75fr 1fr;
    grid-column-gap: 22px;
    & img {
      height: auto;
      max-height: 500px;
      width: 100%;
      object-fit: contain;
    }
  `}
`

const SingleImageGrid = styled.div`
  display: grid;
  place-items: center;
  margin: 30px auto;
  width: fit-content;
  grid-template-columns: 1fr;
`

const DesktopOnlyImg = styled.img`
  display: none;
  width: 400px;

  ${media.tablet`
    display: block;
  `}
`

const MobileOnlyImg = styled.img`
  width: 100%;

  ${media.tablet`
    display: none;
  `}
`

const InputContainer = styled.div`
  width: 100%;
  height: 88px;

  ${media.mobile`
    grid-column: 1 / 5;
  `}
  ${media.tablet`
    max-width: ${(props) => (props.noMaxWidth ? 'none' : '309px')};
    grid-column: ${(props) => (props.grid ? props.grid.col : '1 / 5')};
  `}
`

interface TextInputFormInterface {
  submit: (data: any) => void
  answers: { [key: string]: string }
  className?: string
  screen: any
  Button: React.ReactNode
}
interface FieldsInterface {
  id: string
  validationPattern: any
  type?: string
  placeholder?: string
  required?: boolean
  grid?: {
    [key: string]: string
  }
  options?: string[]
  isOptional?: boolean
}

const Error = ({ id, errors, error }) => (
  <ErrorWrapper>
    <ErrorMessage
      name={id}
      errors={errors}
      message={error && error.message}
      render={(err) => {
        return <p className="error-text">{err.message}</p>
      }}
    />
  </ErrorWrapper>
)

const SharedTextInputForm: React.FC<TextInputFormInterface> = (props) => {
  const {
    Button,
    submit,
    answers,
    className,
    screen: { name, fields, images },
  } = props

  const isShipping = name === 'shipping-information'
  const isUserInfo = name === 'user-information'

  const defaultValues = {
    ...answers,
    shipping_first_name: '',
    shipping_last_name: '',
  }
  if (answers.first_name) defaultValues.shipping_first_name = answers.first_name
  if (answers.last_name) defaultValues.shipping_last_name = answers.last_name

  const { register, handleSubmit, errors } = useForm()

  const handleCtaClick = (data) => {
    const submitData = { ...data }
    if (isShipping) submitData.isShipping = true
    submit(submitData)
  }

  const StateDropdown = ({ field, errors, error, key }) => {
    return (
      <InputContainer grid={field.grid} noMaxWidth={isShipping}>
        <InputWrapper
          id="select-input-wrapper"
          noMaxWidth={isShipping}
          key={key}
        >
          <select
            name={field.id}
            ref={register({ required: 'Required' })}
            aria-label="State"
            className={error ? 'error-input' : ''}
            defaultValue={defaultValues[field.id] || ''}
            style={{ width: '100%' }}
          >
            {field.options.map((option) => (
              <option value={option} key={option}>
                {option}
              </option>
            ))}
          </select>
          <Error id={field.id} errors={errors} error={error} />
        </InputWrapper>
      </InputContainer>
    )
  }

  const twoImages = images && images.desktop && images.mobile
  const singleImage = images && images.desktopOnly && images.mobileOnly

  return (
    <form onSubmit={handleSubmit(handleCtaClick)} className={className}>
      <Grid images={!!images} isUserInfo={isUserInfo}>
        {fields.map((field: FieldsInterface, idx: number) => {
          const { id, type, placeholder } = field
          const error = errors[field.id]

          return isShipping && field.id === 'shipping_state' ? (
            <StateDropdown
              field={field}
              error={error}
              errors={errors}
              key={id}
            />
          ) : (
            <InputContainer grid={field.grid} noMaxWidth={isShipping} key={id}>
              <InputWrapper
                id="text-input-wrapper"
                className={error ? 'error-input-wrapper' : ''}
                noMaxWidth={isShipping}
              >
                <input
                  name={id}
                  type={type}
                  placeholder={placeholder}
                  ref={register({
                    required: field.isOptional ? false : 'Required',
                    pattern: {
                      value: field.validationPattern || '',
                      message: 'Invalid',
                    },
                  })}
                  autoFocus={idx === 0}
                  aria-label={placeholder}
                  className={error ? 'error-input' : ''}
                  defaultValue={defaultValues[id] || ''}
                />
              </InputWrapper>
              <Error id={id} errors={errors} error={error} />
            </InputContainer>
          )
        })}
      </Grid>
      {twoImages && (
        <DoubleImageGrid>
          <img src={images.desktop} alt="Order screenshot - desktop view" />
          <img src={images.mobile} alt="Order screenshot - mobile view" />
        </DoubleImageGrid>
      )}
      {singleImage && (
        <SingleImageGrid>
          <DesktopOnlyImg
            src={images.desktopOnly}
            alt="Order screenshot - desktop view"
          />
          <MobileOnlyImg
            src={images.mobileOnly}
            alt="Order screenshot - mobile view"
          />
        </SingleImageGrid>
      )}
      <Button copy="Continue" arrow type="submit" />
    </form>
  )
}

export default SharedTextInputForm
