import React from 'react'
import styled from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

import RatingSelector from '../RatingSelector'
import { ErrorWrapper } from '../text'

const InputWrapper = styled('div')<{ spanCols: number; textColor: boolean }>`
  grid-column: span ${(props) => props.spanCols || 2};
  max-width: 500px;
  margin: 0 auto;
  position: relative;

  & p {
    color: ${(props) => props.textColor} !important;
  }
`

const CharacterSpan = styled.span`
  width: 10px;
  display: inline-block;
`

interface SharedFeedbackFormInterface {
  submit: (data: any) => void
  answers: { [key: string]: string }
  className?: string
  screen: any
  ratingOptions: number[]
  Button: React.ReactNode
  TitleText: React.ReactNode
  SubtitleText: React.ReactNode
  hideRatings: boolean
}

const SharedFeedbackForm: React.FC<SharedFeedbackFormInterface> = (props) => {
  const {
    Button,
    submit,
    answers,
    className,
    screen,
    TitleText,
    SubtitleText,
    ratingOptions,
    hideRatings,
  } = props

  const { register, handleSubmit, errors, watch, control } = useForm({
    defaultValues: {
      rating: answers.rating || 10,
      comment: answers.comment || '',
    },
  })

  const userComment = watch('comment') || ''

  const minCharacters = 75
  const characterCount = userComment.length || 0

  return (
    <>
      <form onSubmit={handleSubmit(submit)} className={className}>
        {!hideRatings && (
          <Controller
            control={control}
            name="rating"
            render={({ onChange, value, name, ref }) => (
              <RatingSelector
                name={name}
                ratingOptions={ratingOptions}
                selectedRating={value.toString()}
                onChange={onChange}
                answers={answers}
                inputRef={ref}
              />
            )}
            rules={{ required: true }}
          />
        )}

        {screen.secondaryMainCopy && (
          <TitleText>{screen.secondaryMainCopy}</TitleText>
        )}
        {screen.secondarySubCopy && (
          <SubtitleText>{screen.secondarySubCopy}</SubtitleText>
        )}
        <InputWrapper
          spanCols={2}
          textColor={(props) =>
            characterCount === 0
              ? '#999999'
              : characterCount < minCharacters || errors.comment
              ? 'red'
              : props.theme.colors.navy
          }
        >
          <textarea
            name="comment"
            placeholder={screen.secondaryDefaultValue || ''}
            ref={register({
              required: 'This field is required',
              minLength: {
                value: minCharacters,
                message: `Please include ${minCharacters} characters minimum`,
              },
            })}
            aria-label="Feedback comments"
            className={errors.comment ? 'error-input' : ''}
          />
          <p>
            Character count: <CharacterSpan>{characterCount}</CharacterSpan>
          </p>

          <ErrorWrapper>
            <ErrorMessage
              name="comment"
              errors={errors}
              message={errors.comment && errors.comment.message}
              render={(err) => {
                return <p className="error-text">{err.message}</p>
              }}
            />
            {characterCount < minCharacters && !errors.comment && (
              <p className="textarea-label">
                Please include {minCharacters} characters minimum
              </p>
            )}
          </ErrorWrapper>
        </InputWrapper>

        <Button copy="Continue" arrow type="submit" />
      </form>
    </>
  )
}

export default SharedFeedbackForm
