import React from 'react'
import styled from 'styled-components'

import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  z-index: 10;

  * {
    color: ${(props) => props.color || '#000'};
  }
`

const FullPageContainer = styled(SpinnerContainer)`
  height: 100%;
  min-height: 100vh;
  width: 100vw;

  background: ${(props) => props.bg};
  opacity: 0.5;
`

const Icon = styled(LoadingOutlined)`
  font-size: ${(props) => props.size}px;
`

interface SpinnerProps {
  color?: any
  size?: number
  bg?: string
}

const Spinner: React.FC<SpinnerProps> = ({ size = 100, color, bg }) => (
  <SpinnerContainer color={color} bg={bg}>
    <Spin indicator={<Icon spin size={size} />} />
  </SpinnerContainer>
)

export const FullPageSpinner: React.FC<SpinnerProps> = ({
  size = 100,
  color,
  bg,
}) => (
  <FullPageContainer bg={bg}>
    <Spinner size={size} color={color} />
  </FullPageContainer>
)

export default FullPageSpinner
