import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { media } from '../utils/style-helper'

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 16px;
  ${media.mobile`
    width: 100%;
    margin: 30px auto 50px auto;
  `}
  ${media.tablet`
    width: fit-content;
    margin: 60px auto 80px auto;
  `}
`

const ButtonsGrid = styled.div`
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-rows: fit-content;
  margin: 0 auto 23px auto;
  grid-gap: 12px;

  ${media.mobile`
    grid-template-columns: repeat(2, 1fr);
  `}
  ${media.tablet`
    grid-template-columns: repeat(${(props) => props.columns}, 45px);
  `}
  ${media.desktop`
    grid-template-columns: repeat(${(props) => props.columns}, 50px);
  `}
`

const Button = styled.div`
  display: grid;
  place-items: center;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? props.theme.colors.orange : props.theme.colors.lightGrey};
  color: ${(props) => props.theme.colors.navy};
  line-height: 1;
  padding-top: 2px;
  transition: 200ms ease-in-out all;

  &:hover {
    background-color: ${(props) => props.theme.colors.orange};
    color: ${(props) => props.theme.colors.navy};
  }

  & input {
    color: inherit;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
  }

  ${media.mobile`
    width: 100%;
    height: 45px;
    border-radius: 5px;
    & input {
      width: 100%;
      height: 45px;
    }
  `}
  ${media.tablet`
    width: 45px;
    height: 45px;
    border-radius: 50%;
    & input {
      height: 45px;
      width: 45px;
    }
  `}
  ${media.desktop`
    width: 50px;
    height: 50px;
    & input {
      height: 50px;
      width: 50px;
    }
  `}
`

const LabelRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

interface RatingSelectorInterface {
  name: string
  inputRef: any
  ratingOptions: number[]
  selectedRating: string
  onChange: any
  loading?: boolean
  answers: { [key: string]: string }
}

const RatingSelector: React.FC<RatingSelectorInterface> = ({
  name,
  ratingOptions,
  selectedRating,
  onChange,
  loading,
  inputRef,
}) => {
  const optionCount = ratingOptions.length

  return (
    <Container loading={loading}>
      <ButtonsGrid columns={optionCount}>
        {ratingOptions.map((rating) => {
          return (
            <Button
              selected={selectedRating === rating.toString()}
              onClick={(e) => onChange(e.target.value)}
              key={rating}
            >
              <input
                id={rating.toString()}
                name={name}
                value={rating}
                type="button"
                ref={inputRef}
              />
            </Button>
          )
        })}
      </ButtonsGrid>
      <LabelRow>
        <p className="rating-label">1 = Not Satisfied At All</p>
        <p className="rating-label">10 = Extremely Satisfied</p>
      </LabelRow>
    </Container>
  )
}

RatingSelector.propTypes = {
  ratingOptions: PropTypes.array.isRequired,
  register: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedRating: PropTypes.string.isRequired,
  answers: PropTypes.object.isRequired,
}

export default RatingSelector
