import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { SharedNavbar, media } from '@id-purchase-funnel-monorepo/shared'

const Container = styled.div`
  background-color: ${(props) => props.bg};
  width: 100%;
`

const ContentWrapper = styled.div`
  max-width: 1265px;
  margin: 0 auto;
  padding: 24px 40px 12px 40px;
  display: grid;
  place-items: center;
  ${media.mobile`
    max-width: 95%;
  `}
  ${media.tablet`
    max-width: 700px;
  `}
  ${media.desktop`
    max-width: 1265px;
  `}
`

interface StaticQueryInterface {
  logo: {
    childImageSharp: {
      fluid: {
        aspectRatio: number
        sizes: string
        src: string
        srcSet: string
      }
    }
  }
}

export default ({ bg, isSorry }: { bg: string; isSorry: boolean }) => (
  <StaticQuery
    query={query}
    render={(data: StaticQueryInterface) => {
      const logoFileName = isSorry ? 'logoNavy' : 'logoOrange'

      return (
        <SharedNavbar>
          <Container bg={bg}>
            <ContentWrapper>
              <Img
                fluid={data[logoFileName].childImageSharp.fluid}
                alt="Logo"
                style={{ width: 105 }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </ContentWrapper>
          </Container>
        </SharedNavbar>
      )
    }}
  />
)

const query = graphql`
  query {
    logoOrange: file(relativePath: { regex: "images/logo.png/" }) {
      childImageSharp {
        fluid(maxWidth: 105) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    logoNavy: file(relativePath: { regex: "images/logo-navy.png/" }) {
      childImageSharp {
        fluid(maxWidth: 105) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
