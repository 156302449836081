import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'
import { H4, H6, LargeP, P, SmallP } from './shared/text'
import { Button } from './shared/buttons'

import { media } from '@id-purchase-funnel-monorepo/shared'

import DesktopBackground from '../assets/img/yellow-background-desktop.svg'
import MobileBackground from '../assets/img/yellow-background-mobile.svg'

const Container = styled.div`
  height: fit-content;
  min-height: 100vh;
  width: 100%;

  background-color: ${(props) => props.theme.colors.navy};
  color: ${(props) => props.theme.colors.lightGrey};

  display: grid;
  place-items: center;

  ${media.desktop`
    grid-template-columns: 1fr 1fr;
    align-items: start;
  `}
`

const SpacerDiv = styled.div`
  width: 100%;
  height: 100%;
`

const Content = styled.div`
  z-index: 10;
  display: grid;

  width: 100%;
  max-width: 600px;
  height: fit-content;

  ${media.mobile`
    padding: 36px 35px 74px 35px;
    place-items: center;
    text-align: center;
    grid-row-gap: 20px;
    white-space: pre-line;
  `}
  ${media.desktop`
    padding: 36px 30px;
    place-items: start;
    text-align: left;
    grid-row-gap: 14px;
    white-space: initial;
  `}
`

const LogoImg = styled.div`
  width: 129px;

  ${media.mobile`
    margin-bottom: 18px;
  `}
  ${media.desktop`
    margin-bottom: 42px;
  `}
`


const Title = styled(H4)`
  color: ${props => props.theme.colors.orange};
  margin: 0;

  ${media.mobile`
    font-size: 46px;
    line-height: 50px;
  `}
  ${media.desktop`
    font-size: 52px;
    line-height: 70px;
  `}
`

const YellowDiv = styled.div`
  background-repeat: no-repeat;
  background-size: cover;

  ${media.mobile`
    background-image: url(${MobileBackground});
    background-position: top center;
    overflow: hidden;
    position: absolute;
    height: 60%;
    width: 100vw;
    bottom: 0;
    left: 0;
  `}
  ${media.tablet`
    height: 60%;
  `}
  ${media.desktop`
    background-image: url(${DesktopBackground});
    background-position: center left;
    overflow: visible; 
    position: fixed; 
    width: 50vw;
    height: 100vh;
    top: 0;
    bottom: auto;
    right: 0;
    left: auto;
  `}
`

const ButtonLink = styled.a`
  z-index: 50;

  width: fit-content;
  text-decoration: none;

  margin-top: 10px;
`

const MaxWidthGroup = styled.div`
  width: 100%;
  max-width: 363px;

  text-align: center;

  display: grid;
  justify-items: center;
  grid-row-gap: 15px;

  ${media.mobile`
    color: ${(props) => props.theme.colors.navy};
  `}
  ${media.desktop`
    color: ${(props) => props.theme.colors.lightGrey};
  `}
`

const WhiteContainer = styled.div`
  position: relative;

  height: fit-content;

  display: grid;
  place-items: center;
  grid-row-gap: 20px;

  background-color: white;
  border-radius: 36px;

  color: ${(props) => props.theme.colors.navy};
  text-align: center;

  ${media.mobile`
    width: fit-content;
    line-height: 24px;
    margin-bottom: 190px;
    padding: 26px 30px 10px 30px;
  `}
  ${media.tablet`
    margin-bottom: 220px;
  `}
  ${media.desktop`
    width: 100%;
    line-height: 25px;
    margin: 26px 0 1px 0;
    padding: 26px 44px 12px 44px;
  `}
`

const DiscountCodeContainer = styled.div`
  display: grid;
  place-items: center;

  cursor: pointer;
  transition: 300ms all ease-in-out;

  border: 3px solid ${props => props.theme.colors.orange};
  border-radius: 36px;

  ${media.mobile`
    width: 155px;
    height: 44px;  
  `}
  ${media.desktop`
    width: 215px;
    height: 62px;
  `}

  & p {
    line-height: 1;
    ${media.mobile`
      font-size: 24px;
    `}
    ${media.desktop`
      font-size: 32px;
    `}
  }

  &:hover {
    background-color: ${props => props.theme.colors.orange};
    color: white;
  }
`

const EmailLink = styled.a`
  ${media.mobile`
    color: ${props => props.theme.colors.navy};
  `}
  ${media.desktop`
    color: ${props => props.theme.colors.orange};
  `}
`

const LifeStyleImage = styled.div`
  position: absolute;
  z-index: 12;

  ${media.mobile`
    top: 40px;
    left: 50%;

    width: 112vw;
    max-width: 430px;
    transform: translate(-50%, 0);
  `}
  ${media.tablet`
    top: -20px;
    width: 550px;
    max-width: none;
  `}
  ${media.desktop`
    bottom: 0;
    top: auto;
    left: auto;
    right: 0;

    width: 63vw;
    min-width: 500px;
    max-width: 913px;

    transform: translate(0, 0);
  `}
`

const StyledButton = styled(Button)`
  ${media.mobile`
    background-color: ${props => props.theme.colors.navy};
    border-color: ${props => props.theme.colors.navy};
    color: ${props => props.theme.colors.lightGrey};
    & svg path {
      fill: ${props => props.theme.colors.lightGrey};
    }
  `}
  ${media.desktop`
    background-color: ${props => props.theme.colors.orange};
    color: ${props => props.theme.colors.navy};
    & svg path {
      fill: ${props => props.theme.colors.navy};
    }
  `}
`

const fadeIn = keyframes`
    0%: { opacity: 0 }
    100%: { opacity: 1 }
`

const CopiedConfirmation = styled(SmallP)`
  color: ${props => props.theme.colors.teal};
  line-height: 1;
  margin-top: 10px;
  height: 16px;

  & b {
    animation: ${fadeIn} 1s ease;
  }
`

interface GatsbyImageProps {
  childImageSharp: {
    fluid: {
      aspectRatio: number
      sizes: string
      src: string
      srcSet: string
    }
  }
}

interface StaticQueryInterface {
  logo: GatsbyImageProps
  lifestyleImg: GatsbyImageProps
}

const Homepage = ({ data, location }: { location: any;  data: StaticQueryInterface }) => {
  const discountCode = 'GOFINN25'
  const [copied, setCopied] = useState(false)

  const handleDiscountCopy = () => {
    setCopied(true)
    
    const elem = document.createElement('textarea');
    elem.value = discountCode;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);

    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }

  return (
    <Layout location={location} isSorry={false}>
      <Container>
        <Content>
          <LogoImg>
          <Img
            fluid={data.logo.childImageSharp.fluid}
            alt="Logo"
            style={{ width: '100%', height: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
          </LogoImg>
          <Title>This offer is complete!</Title>
          <LargeP>
            {`Heya! Thanks for being \na loyal Finn customer.`}
          </LargeP>
          <MaxWidthGroup>
            <WhiteContainer>
              <P>
                <b>Bad news:</b>
                <br />
                This offer has expired.
              </P>
              <P>
                <b>Good news:</b>
                <br />
                Take <b>25% off</b> ANY Finn product on Amazon with code
              </P>
              <div> 
                <DiscountCodeContainer onClick={handleDiscountCopy}>
                  <H6>{discountCode}</H6>
                </DiscountCodeContainer>
                <CopiedConfirmation>{copied && <b>Copied ✓</b>}</CopiedConfirmation>
              </div>
            </WhiteContainer>

            <P>
              <b>Questions?</b>
              <br />
              We're begging to help! Email us at{' '}
              <EmailLink href="support@petfinn.com">support@petfinn.com</EmailLink>
            </P>

            <ButtonLink href="https://www.amazon.com/stores/page/A3D7B884-81C3-459A-830D-F5A8B535C8E4?maas=maas_adg_9E6FFE0656E1E599B6B1034EA21F2052_afap_abs&ref_=aa_maas" rel="noopener noreferrer">
              <StyledButton  copy="Shop on Amazon" arrow />
            </ButtonLink>
          </MaxWidthGroup>
        </Content>
        <SpacerDiv />
        <YellowDiv>
          <LifeStyleImage onClick={handleDiscountCopy}>
            <Img
              fluid={data.lifestyleImg.childImageSharp.fluid}
              alt="Woman and dog with Finn tins"
              style={{ width: '100%', height: "100%" }}
              imgStyle={{ objectFit: 'contain' }}
            />
          </LifeStyleImage>
        </YellowDiv>
      </Container>
    </Layout>
  )
}

export default ({ location }) => (
  <StaticQuery
    query={query}
    render={(data: StaticQueryInterface) => (
      <Homepage data={data} location={location} />
    )}
  />
)

const query = graphql`
  query {
    logo: file(relativePath: { regex: "images/logo.png/" }) {
      childImageSharp {
        fluid(maxWidth: 128) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    lifestyleImg: file(
      relativePath: { regex: "images/woman-and-dog.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 510, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
