import styled from 'styled-components'

export const ErrorWrapper = styled.div`
  height: 20px;
  text-align: left;
  margin: 0;
  display: flex;
  padding: 2px 0 6px 20px;

  & p.textarea-label {
    text-align: left;
    padding: 6px 0 0 20px;
    font-size: 10px;
    margin: 0;
  }
`
