import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    scroll-behavior: smooth;
    max-width: 100vw;
    overflow-x: hidden;
  }

  body {
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }

  main {
    display: block;
    max-width: 100vw;
    overflow-x: hidden;
  }

  *,
  *::before,
  *::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
  }

  pre {
    font-family: monospace, monospace; 
    font-size: 1em;
  }

  a {
    background-color: transparent;
  }


  img {
    border-style: none;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    background-color: #EEEEEE;
    color: #161345;
    border: none;
    outline: none;
    font-family: inherit;
    font-size: 24px;
    line-height: 1.15;
    margin: 0;
    height: 60px;
  }

  button,
  select {
    text-transform: none;
  }

  select {
    // A reset of styles for custom styling, including removing the default dropdown arrow
    appearance: none;
    background-color: transparent;
    border: none;
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
  }

  select::-ms-expand {
    display: none;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  progress {
    vertical-align: baseline;
  }

  textarea {
    overflow: auto;
  }


  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
`

export default GlobalStyle
